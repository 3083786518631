import logs from "./log.json"
import React from 'react';
import {LocalStore} from "../../utils/LocalStore";
import {Modal} from "antd";

export default class ChangeLog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showChangelog: false,
            changelog: []
        }
    }

    componentDidMount() {
        let version = LocalStore.getInstance().read('version');

        if (version) {
            if (this.isNewerVersionThan(logs[0].version, version)) {
                let changelog = [];
                for (let ii = 0; ii < logs.length; ii++) {
                    if (this.isNewerVersionThan(logs[ii].version, version)) {
                        changelog.push(logs[ii]);
                    } else {
                        break;
                    }
                }

                this.setState({
                    showChangelog: true,
                    changelog: changelog
                })
            }
        }
        else {
            LocalStore.getInstance().save('version', logs[0].version)
        }
    }

    isNewerVersionThan(version, compareTo) {
        let version_args = version.split('.');
        let compareToArgs = compareTo.split('.');
        for (let ii = 0; ii < 4; ii++) {
            if (parseInt(version_args[ii]) > parseInt(compareToArgs[ii])) {
                return true
            }
        }
    }

    markAsRead = () => {
        this.setState({showChangelog: false});
        LocalStore.getInstance().save('version', logs[0].version)
    };

    render() {
        return <Modal title={'Olympus đã được thăng cấp!'} visible={this.state.showChangelog}
                      closable={false}
                      okText={'Tôi đã xem, nhưng màu xanh'}
                      cancelText={'Tôi đã xem'}
                      onOk={this.markAsRead}
                      onCancel={this.markAsRead}>
            {this.state.changelog.map(log => {
                return <div key={log.version}>
                    <h4>{log.version}</h4>
                    {log.new && log.new.length > 0 ? <>
                    <strong>New features:</strong>
                    <ul>{log.new.map(str=><li key={str}>{str}</li>)}</ul>
                    </> : null}

                    {log.improvement && log.improvement.length > 0 ? <>
                        <strong>Improvement:</strong>
                        <ul>{log.improvement.map(str=><li key={str}>{str}</li>)}</ul>
                    </> : null}
                </div>
            })
            }
        </Modal>
    }
}